import Search from "./components/Icons/Search";
import Home from "./components/Icons/Home";
import WApple from "./components/Icons/WAppleIcon";
import wApple from "./components/Icons/wApple.png";
import HomeFill from "./components/Icons/HomeFill";
import Messages from "./components/Icons/Messages";
import MessagesFill from "./components/Icons/MessagesFill";
import Notifications from "./components/Icons/Notifications";
import NotificationFill from "./components/Icons/NotificationFill";
import Profile from "./components/Icons/Profile";
import ProfileFill from "./components/Icons/ProfileFill";
import { MAIN_ROUTES } from "../Main/constants";
const color = "#007897";
export const HEIGHT_COVER_PHOTO = 450;
export const HEIGHT_HEADER = 120;
export const ROUTES = {
  HOME: "/",
  MESSAGES: "/messages",
  NOTIFICATION: "/notifications",
  SEARCH: "/search",
  PROFILE: "/profile",
};
export const ITEMS = [
  {
    color: color,
    Icon: Home,
    FilledIcon: HomeFill,
    label: "Home",
    route: ROUTES.HOME,
    needAuthentication: false,
  },
  {
    color: color,
    Icon: Messages,
    FilledIcon: MessagesFill,
    label: "Messages",
    route: ROUTES.MESSAGES,
    needAuthentication: true,
  },
  {
    color: color,
    Icon: Notifications,
    FilledIcon: NotificationFill,
    label: "Notifications",
    route: ROUTES.NOTIFICATION,
    needAuthentication: true,
  },
  {
    color: color,
    Icon: Profile,
    FilledIcon: ProfileFill,
    label: "Profile",
    route: ROUTES.PROFILE,
    needAuthentication: true,
  },
];

export const ITEMS2 = [
  {
    color: color,
    image: wApple,
    // Icon: WApple,
    // FilledIcon: WApple,
    label: "Home",
    route: ROUTES.HOME,
    needAuthentication: false,
    hideLabel: true,
  },
  {
    color: color,
    Icon: Messages,
    FilledIcon: MessagesFill,
    label: "Messages",
    route: ROUTES.MESSAGES,
    needAuthentication: true,
  },
  {
    color: color,
    Icon: Notifications,
    FilledIcon: NotificationFill,
    label: "Notifications",
    route: ROUTES.NOTIFICATION,
    needAuthentication: true,
  },
  {
    color: color,
    Icon: Profile,
    FilledIcon: ProfileFill,
    label: "Profile",
    route: ROUTES.PROFILE,
    needAuthentication: true,
  },
];

export const EMERGENCY_ITEMS = [
  {
    color: color,
    Icon: Home,
    FilledIcon: HomeFill,
    label: "Home",
    route: ROUTES.HOME,
    needAuthentication: false,
  },
  {
    color: color,
    Icon: Messages,
    FilledIcon: MessagesFill,
    label: "Messages",
    route: ROUTES.MESSAGES,
    needAuthentication: true,
  },
  {
    color: color,
    Icon: Notifications,
    FilledIcon: NotificationFill,
    label: "Notifications",
    route: ROUTES.NOTIFICATION,
    needAuthentication: true,
  },
  {
    color: color,
    image: wApple,
    route: MAIN_ROUTES.HOME,
    needAuthentication: true,
  },
];

export const STICKY_ROUTES = ["CommunityBoard"];

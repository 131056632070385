import LogoWithText from "../../../../kit/Icon/IconWithText";
import React, { useState, useEffect } from "react";
import { Box, Dialog, styled as MuiStyled } from "@mui/material";
import VTTabs from "./components/VTTabs";
import { ABSTRACT_EMERGENCY_POPUP_TABS } from "./constants";
import Map, { MarkerIcon } from "../../../../kit/Map/Map";
import LocationDetails from "./components/LocationDetails";
import { TogetherIconV2 } from "../../../../assets/icons/Logo";
import { BackButton } from "../../../../assets/icons";

const Modal = MuiStyled(Dialog)(() => ({
  "& .MuiDialog-paper": {
    background: "#FAFFFA",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    boxSizing: "border-box",
    minWidth: "100%",
    minHeight: "100%",
    overflowX: "hidden",
    padding: "40px 10px 20px 10px",
    position: "relative",
  },
  "& .MuiBackdrop-root": {
    backgroundColor: "rgba(173, 202, 230, 0.5)",
  },
}));

const PopupZoneMap = ({
  isOpen,
  onClose,
  selectedLocation,
  setSelectedLocation,
  onLocationClick,
  iconWidth,
  iconHeight,
}) => {
  const [activeTabs, setActiveTabs] = useState([
    ABSTRACT_EMERGENCY_POPUP_TABS[0][2].value,
  ]);
  const [showLocationDetails, setShowLocationDetails] = useState(false);
  const [mapCenter, setMapCenter] = useState(null);

  useEffect(() => {
    // Use the Geolocation API to get the user's current position
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setMapCenter({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });
        },
        (error) => {
          console.error("Error fetching location:", error);
          setMapCenter({ lat: 32.0853, lng: 34.7818 });
        }
      );
    } else {
      console.warn("Geolocation is not supported by this browser.");
      setMapCenter({ lat: 32.0853, lng: 34.7818 });
    }
  }, []);

  const handleMarkerClick = (location) => {
    setSelectedLocation(location);
    setShowLocationDetails(true);
  };

  return (
    <Modal open={isOpen} onClose={onClose}>
      <Box
        sx={{ position: "absolute", left: "10px", cursor: "pointer" }}
        onClick={onClose}
      >
        <BackButton width={75} height={45} />
      </Box>
      <Box
        sx={{
          width: "100%",
          textAlign: "center",
          marginBottom: "20px",
        }}
      ></Box>
      <LogoWithText
        left={"-90px"}
        top={"40px"}
        width={"150px"}
        iconHeight="60"
        iconWidth="200"
        Icon={TogetherIconV2}
        title={"In Emergency"}
        titleStyles={`
              font-size: 15px;
              color: #EF7300;
              font-weight: 500;
            `}
      />
      <Box
        sx={{
          position: "relative",
          width: "100%",
          height: "100%",
          gap: "50px",
        }}
      >
        <VTTabs
          tabsGroup={ABSTRACT_EMERGENCY_POPUP_TABS}
          activeTabs={activeTabs}
          setActiveTabs={setActiveTabs}
          width={"100%"}
          allowMultiSelect={false}
        />
        {mapCenter && (
          <Map
            placesType={activeTabs}
            initialSelectedLocation={mapCenter}
            onMarkerClick={handleMarkerClick}
            disableClick
            borderRadius={"20px"}
            defaultColor={MarkerIcon.Blue}
            zoom={900}
          />
        )}
        {showLocationDetails && selectedLocation && (
          <LocationDetails
            location={selectedLocation}
            reset={() => {
              setShowLocationDetails(false);
              setSelectedLocation(null);
            }}
            onClick={onLocationClick}
          />
        )}
      </Box>
    </Modal>
  );
};

export default PopupZoneMap;

import React, { useContext } from "react";
import { FlexColumn } from "../../../../Common/Styled";
import styled from "styled-components";
import MuiButton from "../../../../../kit/Button/MuiButton";
import { hospitalBusIconV2, needHelpIconV2 } from "../../images";
import BellIcon from "../../../../../assets/icons/Activities/LandingPage/BellIcon";
import { EmergencyOverlaysContext } from "../../../../Emergency/Contacts/EmergencyOverlaysContext";

const ButtonWrapper = styled.div`
  background-color: ${(props) => props.backgroundColor || "#E81616"};
  border-radius: 10px;
  padding: 3px;
  cursor: pointer;
  width: 100%;
`;

const buttonStyles = {
  color: "#FFF",
  fontSize: 15,
  fontWeight: 600,
  fontFamily: "Assistant",
  border: "3px solid #FFF",
  borderRadius: "10px",
  width: "100%",
  height: 64,
  textTransform: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  padding: "0 25px",
  position: "relative",
  lineHeight: "normal",
  boxSizing: "border-box",
  "&:hover": {
    border: "3px solid #FFF",
  },
};

const EmergenciesButtons = ({
  isNeedHelp,
  isSOSAndNeedHelp,
  isAllEmergencies,
  isAbstractEmergency,
}) => {
  const {
    setShowOverlay,
    setOverlayProps,
    setReassurance,
    setShowEmergencyMap,
  } = useContext(EmergencyOverlaysContext);

  return (
    <FlexColumn
      sx={{
        marginTop: "30px",
        marginBottom: "25px",
        width: "100%",
        boxSizing: "border-box",
        padding: "0 27px",
        justifyContent: "center",
        alignItems: "center",
        gap: "22px",
      }}
    >
      <ButtonWrapper
        onClick={() => {
          setShowEmergencyMap(false);
          setShowOverlay(true);
          setOverlayProps({
            isNeedHelp: true,
          });
        }}
        backgroundColor={"#0270A5"}
      >
        <MuiButton
          sx={{
            ...buttonStyles,
            backgroundColor: "#0270A5",
            "&:hover": {
              backgroundColor: "#0270A5",
            },
          }}
        >
          <img
            style={{
              width: "43.73px",
              height: "46.44px",
            }}
            src={needHelpIconV2}
            alt="helpNeededIcon"
          />
          <FlexColumn sx={{ width: "150px" }}>
            <div>בקשת סיוע אישית</div>
            <div>Some Help is Needed</div>
          </FlexColumn>
        </MuiButton>
      </ButtonWrapper>
      {(isAbstractEmergency || isAllEmergencies || isSOSAndNeedHelp) && (
        <ButtonWrapper
          onClick={() => {
            setShowEmergencyMap(false);
            setShowOverlay(true);
            setReassurance(false);
            setOverlayProps({
              isSOS: true,
              isBlue: true,
            });
          }}
        >
          <MuiButton
            sx={{
              ...buttonStyles,
              backgroundColor: "#E81616",
            }}
          >
            <BellIcon width={"41.22"} height={"41.66"} color={"#FFF"} />
            <FlexColumn sx={{ width: "150px" }}>
              <div>לחצו במצוקה</div>
              <div>Emergency Call</div>
            </FlexColumn>
          </MuiButton>
        </ButtonWrapper>
      )}
      {(isAbstractEmergency || isAllEmergencies) && (
        <ButtonWrapper
          onClick={() => {
            setShowEmergencyMap(false);
            setShowOverlay(true);
            setReassurance(false);
            setOverlayProps({
              isOrganization: true,
            });
          }}
          backgroundColor={"#EF7300"}
        >
          <MuiButton
            sx={{
              ...buttonStyles,
              backgroundColor: "#EF7300",
            }}
          >
            <img
              style={{
                width: "63.1px",
                height: "63.1px",
              }}
              src={hospitalBusIconV2}
              alt="hospitalBusIcon"
            />
            <FlexColumn sx={{ width: "150px" }}>
              <div>ארגוני חירום והצלה</div>
              <div>Organizations</div>
            </FlexColumn>
          </MuiButton>
        </ButtonWrapper>
      )}
    </FlexColumn>
  );
};

export default EmergenciesButtons;

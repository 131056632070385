// src/hooks/usePlaceDetails.js
import { useQuery } from "@tanstack/react-query";

export const usePlaceDetails = (placeId) => {
  return useQuery(
    ["placeDetails", placeId],
    () => {
      return new Promise((resolve, reject) => {
        if (window.google && window.google.maps && window.google.maps.places) {
          // Create a dummy map instance (not displayed)
          const map = new window.google.maps.Map(document.createElement("div"));
          const service = new window.google.maps.places.PlacesService(map);

          const request = {
            placeId: placeId,
            fields: [
              "name",
              "formatted_address",
              "formatted_phone_number",
              "opening_hours",
              "website",
              "geometry",
              "types",
            ],
            language: "he",
          };

          service.getDetails(request, (place, status) => {
            if (status === window.google.maps.places.PlacesServiceStatus.OK) {
              resolve(place);
            } else {
              console.error("Error fetching place details:", status);
              reject(new Error(`Failed to fetch place details: ${status}`));
            }
          });
        } else {
          reject(new Error("Google Maps JavaScript API is not loaded."));
        }
      });
    },
    {
      enabled: !!placeId, // Only run the query if placeId is truthy
      staleTime: 1000 * 60 * 5, // 5 minutes
      cacheTime: 1000 * 60 * 30, // 30 minutes
      retry: 1, // Retry once on failure
    }
  );
};

import { useContext } from "react";
import { LanguageContext } from "../store/languageContext";
import { LANDING_ROUTES, REGION } from "../domain/Common/Layout/Main/constants";
import { useLocation } from "react-router-dom";

function useRegion(props) {
  const { region, setRegion } = useContext(LanguageContext);
  const location = useLocation();
  const currentPath = location.pathname;

  return {
    region,
    setRegion,
    isLocal: region === REGION.lOCAL,
    isWorldwide: region === REGION.WORLD_WIDE,
    isGderotSettlements: region === REGION.GDEROT_SETTLEMENTS,
    isDOD: region === REGION.DOD,
    isGderotCommunityCenter: region === REGION.GderotCommunityCenter,
    isFences48: region === REGION.Fences48,
    isBestFriends: region === REGION.BestFriends,
    isEmergency: region === REGION.Emeregncy,
    isTelAviv: region === REGION.TelAviv,
    isRamatHasharon: region === REGION.RamatHasharon,
    isTaglit: region === REGION.taglit,
    isSos: region === REGION.sos,
    isJewish: region === REGION.jewish,
    isIsrael: region === REGION.israel,
    isVet: region === REGION.vet,
    isEducation: region === REGION.education,
    isStudent: region === REGION.student,
    isGderot: region === REGION.gderot,
    isAbstractTelAviv: region === REGION.Abstract_TelAviv,
    isAbstractGderot: region === REGION.Abstract_Gderot,
    isAbstractEmergency: region === REGION.Abstract_Emergency,
    isVolunteersAndSpendTime: region === REGION.VolunteersAndSpendTime,
    isJewishVAST: region === REGION.JewishVAST,
    isJewishEmergency:
      region === REGION.JewishEmergency ||
      currentPath === LANDING_ROUTES.JEWISH_EMERGENCY,
    isGderotV2:
      region === REGION.gderotV2 || currentPath === LANDING_ROUTES.GDEROT,
    isMatnasim:
      region === REGION.matnasim || currentPath === LANDING_ROUTES.MATNASIM,
    isHatzofim:
      region === REGION.hatzofim || currentPath === LANDING_ROUTES.HATZOFIM,
    isSosV2: region === REGION.sosV2,
    isVTime: region === REGION.vTime,
    isNeedHelp: region === REGION.NeedHelp,
    isSOSAndNeedHelp: region === REGION.SOSAndNeedHelp,
    isAllEmergencies: region === REGION.AllEmergencies,
  };
}

export default useRegion;
